<template>
  <ul class="space-y-4 pr-96">
    <!-- html likethis cause don't know api structure -->
    <li>
      <h2 class="text-black-900 font-medium text-base mb-2">Mailing Address</h2>

      <div class="text-black-600 text-base">
        <span v-if="publisher.name"> {{ publisher.name }}<br /> </span>
        <span v-if="publisher.addressLine1">
          {{ publisher.addressLine1 }} <br />
        </span>
        <span v-if="publisher.addressLine2">
          {{ publisher.addressLine2 }} <br />
        </span>
        <span v-if="publisher.country || publisher.postalCode">
          {{ publisher.country }} {{ publisher.postalCode }}
        </span>
      </div>
    </li>
    <li v-if="publisher.organizationPhone">
      <h2 class="text-black-900 font-medium text-base mb-2">Telephone</h2>

      <div class="text-black-600 text-base hover:text-orange-900">
        <a :href="`tel:${publisher.organizationPhone}`">
          {{ publisher.organizationPhone }}
        </a>
      </div>
    </li>
    <li v-if="publisher.organizationFax">
      <h2 class="text-black-900 font-medium text-base mb-2">Fax</h2>

      <div class="text-black-600 text-base hover:text-orange-900">
        <a :href="`tel:${publisher.organizationFax}`">
          {{ publisher.organizationFax }}
        </a>
      </div>
    </li>
    <li v-if="publisher.organizationEmail">
      <h2 class="text-black-900 font-medium text-base mb-2">E-mail</h2>

      <div class="text-black-900 text-base">
        <a
          class="underline hover:text-orange-900"
          :href="`mailto:${publisher.organizationEmail}`"
          >{{ publisher.organizationEmail }}
        </a>
      </div>
    </li>
    <li v-if="publisher.organizationWebsite">
      <h2 class="text-black-900 font-medium text-base mb-2">Website</h2>

      <div class="text-black-900 text-base">
        <a
          class="underline hover:text-orange-900"
          :href="publisher.organizationWebsite"
          >{{ publisher.organizationWebsite }}
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TabInformation",
  computed: {
    ...mapGetters(["publisher"]),
  },
};
</script>
