<template>
  <!-- pl-24 -->
  <div class="rounded py-8 pr-3 bg-grey-400 flex items-center">
    <div class="max-w-xs w-full flex-shrink-0 flex mx-3">
      <router-link
        v-if="publisher.handle"
        :to="{ name: 'publisher', params: { handle: publisher.handle } }"
        class="
          flex
          rounded
          w-145
          h-145
          m-auto
          bg-white-900
          filter
          drop-shadow-md
        "
      >
        <custom-image
          :src="publisher.imageUrl"
          imgClass="h-auto m-auto"
          :width="120"
        />
      </router-link>
    </div>

    <div class="max-w-screen-sm">
      <router-link
        v-if="publisher.handle"
        :to="{ name: 'publisher', params: { handle: publisher.handle } }"
      >
        <h1
          class="
            text-black-900 text-3xl
            font-medium
            mb-4
            inline-block
            hover:text-orange-900
          "
        >
          {{ publisher.name }}
        </h1>
      </router-link>
      <div class="space-y-3">
        <p class="text-black-600 text-base">
          {{ publisher.shortDescription }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CustomImage from "@/components/utils/CustomImage.vue";

export default {
  name: "CardPublisherDetail",
  components: {
    CustomImage,
  },
  props: {
    publisher: {
      type: Object,
      required: true,
    },
  },
};
</script>
