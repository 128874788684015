<template>
  <div class="mt-2 bg-white-900 mb-14">
    <div class="container mx-auto">
      <base-breadcrumbs
        :links-list="publisherPage.breadcrumbsList"
        class="mb-6"
      />
      <card-publisher-detail class="mb-12" :publisher="publisher" />
      <tab-navigation
        @set-current-tab="setCurrentTab"
        :currentTab="currentTab"
        :tabs="tabs"
        class="mb-5"
      />
      <actions-bar
        v-if="currentTabComponent && currentTabComponent.actionsBar"
        title-class="text-2xl"
        :title="currentTabComponent.title"
        :placeholder="currentTabComponent.placeholder"
        :export-button="currentTabComponent.actionsButton"
        @export-to-pdf="exportToPdf"
        :sortByType="sortByType"
        :sortByValue="sortBy"
        :searchValue="searchValue"
        :hasSortByRelevance="hasSortByRelevance"
        @handleSortByChange="handleSortByChange"
        @handleSearchValueChange="searchValue = $event"
        @handleSearch="handleSearch"
        @handleSearchClear="handleSearchClear"
      />
      <component
        v-if="currentTabComponent"
        :is="`tab-${currentTabComponent.tabData}`"
        :show-publishers="false"
        :isCatalogsLoading="isPublisherCatalogsLoading"
        :catalogs="publisherCatalogs"
        :catalogsCount="publisherCatalogsCount"
        :isBooksLoading="isPublisherBooksLoading"
        :books="publisherBooks"
        :booksCount="publisherBooksCount"
        :filterParams="filterParams"
        :filters="filters"
        @handleFilters="handleFilters"
        @handleFetchMore="handleFetchMore"
      ></component>
    </div>

    <div class="vue-html2pdf">
      <transition name="transition-anim">
        <section class="pdf-preview" v-if="pdfFile">
          <button @click.self="closePdf()">&times;</button>
          <iframe :src="pdfFile" width="100%" height="100%" />
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

import CardPublisherDetail from "@/components/cards/CardPublisherDetail.vue";
import TabNavigation from "@/components/base/TabNavigation.vue";
import TabCatalogs from "@/components/tabs/Catalogs.vue";
import TabBooks from "@/components/tabs/Books.vue";
import TabInformation from "@/components/tabs/Information.vue";
import ActionsBar from "@/components/base/ActionsBar.vue";
import Breadcrumbs from "@/components/base/Breadcrumbs.vue";
import {
  REFRESH_PUBLISHER_PAGE,
  GET_PUBLISHER,
  FETCH_PUBLISHER_CATALOGS,
  FETCH_MORE_PUBLISHER_CATALOGS,
  FETCH_PUBLISHER_BOOKS,
  FETCH_MORE_PUBLISHER_BOOKS,
  FETCH_PUBLISHER_BOOKS_FILTER_PARAMS,
} from "@/store/actions.type";
import {
  RELEVANCE_SORT_BY,
  DEFAULT_CATALOGS_SORT_BY,
  DEFAULT_BOOKS_SORT_BY,
} from "@/common/constants";
import { PublisherService } from "@/common/api.service";

export default {
  name: "PublisherDetail",
  components: {
    "tab-navigation": TabNavigation,
    "card-publisher-detail": CardPublisherDetail,
    "tab-catalogs": TabCatalogs,
    "tab-books": TabBooks,
    "tab-information": TabInformation,
    "actions-bar": ActionsBar,
    "base-breadcrumbs": Breadcrumbs,
  },
  async mounted() {
    this.$store.dispatch(REFRESH_PUBLISHER_PAGE);
    await this.$store.dispatch(GET_PUBLISHER, this.$route.params.handle);

    if (this.publisher.catalogCount > 0) {
      this.tabs.push({
        id: 1,
        name: "All Catalogs",
        tabData: "catalogs",
        actionsBar: true,
        actionsButton: false,
        placeholder: "Search by name",
        title: `Catalogs total: 0`,
      });
    }

    if (
      this.isAuthenticated &&
      (this.user.type === "admin" ||
        this.user.type === "reseller" ||
        this.publisher.isPublisherAccount)
    ) {
      this.tabs.push({
        id: 2,
        name: "Information Page",
        tabData: "information",
        actionsBar: false,
      });
    }

    switch (this.currentTab) {
      case 0:
        await this.fetchPublisherBooks();
        Vue.set(
          this.tabs[0],
          "title",
          `Books total: ${this.publisherBooksCount}`
        );

        if (this.isAuthenticated) {
          this.fetchPublisherBooksFilterParams();
        }
        break;
      case 1:
        await this.fetchPublisherCatalogs();
        Vue.set(
          this.tabs[1],
          "title",
          `Catalogs total: ${this.publisherCatalogsCount}`
        );
        break;
    }
  },
  data() {
    const currentTab = this.$route.query.t ? parseInt(this.$route.query.t) : 0;

    let sortBy = this.$route.query.s;
    if (!sortBy) {
      if (this.$route.query.q) {
        sortBy = RELEVANCE_SORT_BY;
      } else {
        switch (currentTab) {
          case 0:
            sortBy = DEFAULT_BOOKS_SORT_BY;
            break;
          case 1:
            sortBy = DEFAULT_CATALOGS_SORT_BY;
            break;
        }
      }
    }

    let filters = {};
    switch (currentTab) {
      case 0:
        filters = {
          imprints: [],
          subjects: [],
          publishingDate: [],
          authors: [],
          priceRange: [],
          available: "",
        };

        if (this.$route.query.fi) {
          filters.imprints = JSON.parse(
            decodeURIComponent(this.$route.query.fi)
          );
        }

        if (this.$route.query.fs) {
          filters.subjects = JSON.parse(
            decodeURIComponent(this.$route.query.fs)
          );
        }

        if (this.$route.query.fpd) {
          filters.publishingDate = JSON.parse(
            decodeURIComponent(this.$route.query.fpd)
          );
        }

        if (this.$route.query.fa) {
          filters.authors = JSON.parse(
            decodeURIComponent(this.$route.query.fa)
          );
        }

        if (this.$route.query.fpr) {
          filters.priceRange = JSON.parse(
            decodeURIComponent(this.$route.query.fpr)
          );
        }

        if (this.$route.query.fav) {
          filters.available = JSON.parse(
            decodeURIComponent(this.$route.query.fav)
          );
        }

        break;
      case 1:
        filters = {
          createdDate: [],
          updatedDate: [],
        };

        if (this.$route.query.fcd) {
          filters.createdDate = JSON.parse(
            decodeURIComponent(this.$route.query.fcd)
          );
        }

        if (this.$route.query.fud) {
          filters.updatedDate = JSON.parse(
            decodeURIComponent(this.$route.query.fud)
          );
        }

        break;
    }

    return {
      tabs: [
        {
          id: 0,
          name: "All Books",
          tabData: "books",
          actionsBar: true,
          actionsButton: true,
          placeholder: "Search by name, author, ISBN",
          title: "Books total: 0",
        },
      ],
      currentTab,
      searchValue: this.$route.query.q ? this.$route.query.q : "",
      sortBy,
      filters,
      page: 1,
      pdfFile: null,
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "user",
      "publisherPage",
      "publisher",
      "isPublisherCatalogsLoading",
      "publisherCatalogs",
      "publisherCatalogsCount",
      "isPublisherBooksLoading",
      "publisherBooks",
      "publisherBooksCount",
      "publisherBooksFilterParams",
    ]),
    currentTabComponent() {
      return this.tabs.find((tab) => tab.id === this.currentTab);
    },
    hasSortByRelevance() {
      return this.$route.query.q && this.$route.query.q.length > 0;
    },
    sortByType() {
      switch (this.currentTab) {
        case 0:
          return "books";
        case 1:
          return "catalogs";
        default:
          return "";
      }
    },
    filterParams() {
      return this.currentTab === 0 ? this.publisherBooksFilterParams : {};
    },
  },
  methods: {
    getQueryParams() {
      return { q: this.searchValue, s: this.sortBy, f: this.filters };
    },
    updateQueryParams(isUpdateQuery) {
      let query = {
        t: this.currentTab,
      };

      if (this.currentTab === 0 || this.currentTab === 1) {
        const q = isUpdateQuery ? this.searchValue : this.$route.query.q;
        if (q && q.length > 0) {
          query["q"] = q;
        }

        if (this.sortBy && this.sortBy.length > 0) {
          query["s"] = this.sortBy;
        }
      }

      switch (this.currentTab) {
        case 0:
          if (this.filters.imprints.length > 0) {
            query["fi"] = encodeURIComponent(
              JSON.stringify(this.filters.imprints)
            );
          }

          if (this.filters.subjects.length > 0) {
            query["fs"] = encodeURIComponent(
              JSON.stringify(this.filters.subjects)
            );
          }

          if (this.filters.publishingDate.length > 0) {
            query["fpd"] = encodeURIComponent(
              JSON.stringify(this.filters.publishingDate)
            );
          }

          if (this.filters.authors.length > 0) {
            query["fa"] = encodeURIComponent(
              JSON.stringify(this.filters.authors)
            );
          }

          if (this.filters.priceRange.length > 0) {
            query["fpr"] = encodeURIComponent(
              JSON.stringify(this.filters.priceRange)
            );
          }

          if (this.filters.available.length > 0) {
            query["fav"] = encodeURIComponent(
              JSON.stringify(this.filters.available)
            );
          }

          break;
        case 1:
          if (this.filters.createdDate.length > 0) {
            query["fcd"] = encodeURIComponent(
              JSON.stringify(this.filters.createdDate)
            );
          }

          if (this.filters.updatedDate.length > 0) {
            query["fud"] = encodeURIComponent(
              JSON.stringify(this.filters.updatedDate)
            );
          }

          break;
      }

      this.$router.push({
        name: "publisher",
        params: {
          handle: this.publisher.handle,
        },
        query,
      });
    },
    async fetchPublisherCatalogs() {
      await this.$store.dispatch(FETCH_PUBLISHER_CATALOGS, {
        slug: this.publisher.id,
        params: this.getQueryParams(),
      });
    },
    async fetchPublisherBooks() {
      await this.$store.dispatch(FETCH_PUBLISHER_BOOKS, {
        slug: this.publisher.id,
        params: this.getQueryParams(),
      });
    },
    fetchPublisherBooksFilterParams() {
      this.$store.dispatch(FETCH_PUBLISHER_BOOKS_FILTER_PARAMS, {
        slug: this.publisher.id,
        params: {
          q: this.searchValue,
        },
      });
    },
    setCurrentTab(tabId) {
      this.currentTab = tabId;
      this.searchValue = "";
      this.resetFilters();
      this.updateQueryParams(true);
    },
    resetFilters() {
      let filters = {};
      switch (this.currentTab) {
        case 0:
          filters = {
            imprints: [],
            subjects: [],
            publishingDate: [],
            authors: [],
            priceRange: [],
            available: "",
          };

          break;
        case 1:
          filters = {
            createdDate: [],
            updatedDate: [],
          };

          break;
      }
      this.filters = filters;
    },
    handleSortByChange(event) {
      this.sortBy = event;
      this.updateQueryParams(false);
    },
    handleSearch() {
      if (this.searchValue.length > 0) {
        this.sortBy = RELEVANCE_SORT_BY;
      } else {
        switch (this.currentTab) {
          case 0:
            this.sortBy = DEFAULT_BOOKS_SORT_BY;
            break;
          case 1:
            this.sortBy = DEFAULT_CATALOGS_SORT_BY;
            break;
        }
      }
      this.updateQueryParams(true);
    },
    handleSearchClear() {
      this.searchValue = "";
      switch (this.currentTab) {
        case 0:
          this.sortBy = DEFAULT_BOOKS_SORT_BY;
          break;
        case 1:
          this.sortBy = DEFAULT_CATALOGS_SORT_BY;
          break;
      }
      this.page = 1;
      this.updateQueryParams(true);
    },
    handleFilters({ key, value }) {
      this.filters = {
        ...this.filters,
        [key]: value,
      };
      this.updateQueryParams(false);
    },
    handleFetchMore() {
      this.page++;
      switch (this.currentTab) {
        case 0:
          this.$store.dispatch(FETCH_MORE_PUBLISHER_BOOKS, {
            slug: this.publisher.id,
            params: { ...this.getQueryParams(), p: this.page },
          });
          break;
        case 1:
          this.$store.dispatch(FETCH_MORE_PUBLISHER_CATALOGS, {
            slug: this.publisher.id,
            params: { ...this.getQueryParams(), p: this.page },
          });
          break;
      }
    },
    async exportToPdf() {
      const { data } = await PublisherService.getPdf(this.publisher.id);
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.pdfFile = blobUrl;
    },
    closePdf() {
      URL.revokeObjectURL(this.pdfFile);
      this.pdfFile = null;
    },
  },
};
</script>
