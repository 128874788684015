<template>
  <ul class="space-x-20 flex items-center border-b border-grey-500">
    <li v-for="tab in tabs" :key="tab.id">
      <button
        @click="$emit('set-current-tab', tab.id)"
        class="
          text-lg
          uppercase
          font-medium
          text-grey-700
          pb-4
          border-b-2 border-opacity-0 border-orange-900
          hover:text-black-900
        "
        :class="{
          'border-opacity-100 text-orange-900': currentTab === tab.id,
        }"
      >
        {{ tab.name }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    currentTab: {
      type: Number,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
};
</script>
